import DownloadSection from "./components/DownloadSection";
import Navbar from "./components/Navbar";

import ShippingMessage from "./components/ShippingMessage";
import Topnav from "./components/Topnav";
import Women from "./components/Women";
import womenP from "./assets/women.jpg";
import womenL from "./assets/womenLarge.jpg";
import legacyL from "./assets/legacyL.jpg";
import legacyP from "./assets/legacyP.jpg";
import solol from "./assets/SoloL.jpg";
import soloP from "./assets/soloP.jpg";
import deuux from "./assets/deux.webp";
import deuuxP from "./assets/deuxP.webp";
import twoL from "./assets/twoL.webp";
import twoP from "./assets/twoP.webp";
import sportL from "./assets/sportL.webp";
import sportP from "./assets/sportP.webp";
import { useState } from "react";
import {
	elevateShop,
	seamlessShop,
	newReleasesShop,
	womenSport,
	womenTwo,
	womenOne,
	womenElevate,
	womenLegacy,
	womenData,
} from "./constants/Menu";

import Shop from "./components/Shop";
import Collection from "./components/Collection";

function App() {
	const [openMenu, setOpenMenu] = useState(false);

	return (
		<div className="">
			<Topnav />
			<Navbar openMenu={openMenu} setOpenMenu={setOpenMenu} />
			<div className={`${!openMenu ? "block " : "hidden md:block"}`}>
				<ShippingMessage />
				<DownloadSection />
				<Women
					imagePhone={legacyP}
					imageLarge={legacyL}
					data={womenLegacy}
					style={{
						title: "md:text-white",
						ptag: "md:text-white ",
						button: "md:bg-white md:text-black",
					}}
				/>
				<Shop title={"NEW RELEASES"} data={newReleasesShop} />

				<Women
					imagePhone={womenP}
					imageLarge={womenL}
					data={womenData}
					style={{
						title: "md:text-black",
						ptag: "md:text-neutral-700 ",
						button: "md:bg-black md:text-white",
					}}
				/>
				<Shop title={"SEAMLESS"} data={seamlessShop} />

				<Women
					imagePhone={soloP}
					imageLarge={solol}
					data={womenElevate}
					style={{
						title: "md:text-black",
						ptag: "md:text-neutral-700 ",
						button: "md:bg-black md:text-white",
					}}
				/>
				<Shop title={"ELEVATE"} data={elevateShop} />

				<Women
					imagePhone={deuuxP}
					imageLarge={deuux}
					data={womenOne}
					style={{
						title: "md:text-black",
						ptag: "md:text-neutral-700 ",
						button: "md:bg-black md:text-white",
						one: true,
					}}
				/>
				<Shop title={"FIND"} data={newReleasesShop} />

				<Women
					imagePhone={twoP}
					imageLarge={twoL}
					data={womenTwo}
					style={{
						title: "md:text-white",
						ptag: "md:text-white ",
						button: "md:bg-white md:text-black",
					}}
				/>
				<Shop title={"TWO IN ONE"} data={elevateShop} />

				<Women
					imagePhone={sportP}
					imageLarge={sportL}
					data={womenSport}
					style={{
						title: "md:text-black",
						ptag: "md:text-neutral-700 ",
						button: "md:bg-black md:text-white",
					}}
				/>
				<Shop title={"THE BEST"} data={seamlessShop} />
				<Collection />
			</div>
		</div>
	);
}

export default App;
