import collectionleggigns from "../assets/collectionLeggigns.webp";
import collectionbras from "../assets/collectionbras.webp";
import collectionshort from "../assets/collectionshort.webp";
import collectionview from "../assets/collectionview.webp";
import TBAR from "../assets/T-Bar.webp";
import Leggins from "../assets/Leggins.webp";
import Oversize from "../assets/oversized.webp";
import Tight from "../assets/tight.webp";
import seamlessleggigns from "../assets/seamlessleggigns.webp";
import seamlessvital from "../assets/seamlessvital.webp";
import seamlessvital2 from "../assets/seamlessvital2.webp";
import seamlesscroptop from "../assets/seamlesscroptop.webp";
export const topMenu = [
	"Blog",
	"Email sing up",
	"Help",
	"Accessibility Statement",
];
const treadingw = [
	"New Releases",
	"All Sale",
	"Valkyrae",
	"Best Sellers",
	"Hattie Bourn",
	"What Em Wore",
	"E-Gift Card",
];
const productsw = [
	"All Products ",
	"All In One",
	"CropTops",
	"joggers",
	"Leggins",
	"Hoodies",
	"Jakets",
	"Shorts",
	"Sport Bras",
	"Slides",
	"T-Shirts & Tops",
	"Underwear",
	"Vests",
];
const collectionsw = [
	"Seamless",
	"Adapt",
	"Flex",
	"Essentials",
	"Vital Seamless",
	"Whitney Simmons",
	"Sweat",
	"key Fits",
];
const HDYTw = [
	"Running",
	"Lifting Essentials ",
	"Rest Day",
	"Pump Cover",
	"Functional Fitness",
];
const accessoriesw = [
	"All Accessories",
	"Bags",
	"All Equipment",
	"Bottles",
	"All Headwear",
	"All Socks",
	"Underwear",
];
const treadingm = ["New Releases", "All Sale", "Best Sellers", "E-Gift Card"];
const productsm = [
	"All Products ",
	"Base Layers",
	"joggers",
	"Leggins",
	"Jakets",
	"Hoodies",
	"Pullovers",
	"Shorts",
	"Stingers",
	"Tank",
	"T-Shirts & Tops",
	"Slides",
	"Swimwear",
];
const collectionsm = ["Essentials", "315 Collection", "Apex", "Power", "Sport"];
export const Woman = {
	TRENDING: treadingw,
	PRODUCTS: productsw,
	COLLECTION: collectionsw,
	"HOW DO YOU TRAIN?": HDYTw,
	ACCESSOIRES: accessoriesw,
};
export const Men = {
	TRENDING: treadingm,
	PRODUCTS: productsm,
	COLLECTION: collectionsm,
	"HOW DO YOU TRAIN?": HDYTw,
	ACCESSOIRES: accessoriesw,
};
export const more = [
	"Blog",
	"Email Sing Up",
	"Help",
	"Accessibility Statement ",
];
export const accessories = {
	"SHOP ALL": ["All Accessories", "New Releases"],
	FOOTWEAR: ["Slides"],
	SOCKS: [
		"All Socks",
		"Crew Socks",
		"Quater Socks",
		"No Shadow Socks",
		"Trainer Socks",
	],
	BAGS: ["All Bags", "Gym Backpacks", "Dufflel Bags", "Small Bags"],
	UNDERWEAR: ["Women Underwear", "Men Underwear"],
	HEADWEAR: ["All Headwear", "Caps", "Beanies", "Hair Accessories"],
	EQUIPMENT: [
		"All Equipement",
		"Weightlifting",
		"Resistance Bands",
		"Bottles",
	],
};
export const collections = [
	{
		img: collectionleggigns,
		text: "LEGGINGS",
	},
	{
		img: collectionbras,
		text: "SPORTS BRAS",
	},
	{
		img: collectionshort,
		text: "SHORTS",
	},
	{
		img: collectionview,
		text: "VIEW ALL",
	},
];

export const womenData = {
	title: "GYMSHARK SEAMLESS",
	paragraphe: " There’s a Seamless for everyone. Wanna find yours?",
	button: "FIND YOUR FIT",
};
export const womenLegacy = {
	title: "LEGACY",
	paragraphe:
		" The lifting collection that combines old school fits with new school features.",
	button: "SHOP LEGACY",
};
export const womenElevate = {
	title: "ELEVATE YOUR EVERYDAY",
	paragraphe:
		" From brunch to barre, Elevate's comfortable, versatile staples are with you every step of the way.",
	button: "SHOP THE COLLECTION",
};
export const womenOne = {
	title: "FIND THE ONE",
	paragraphe:
		"Sports bras more trustworthy, supportive and reliable than your ex",
	button: "SHOP",
};
export const womenTwo = {
	title: "GET YOU A ‘FIT THAT CAN DO BOTH",
	paragraphe:
		"These athleisure staples are your new go-to for literally anything",
	button: "SHOP NOW",
};
export const womenSport = {
	title: "NOTHING BUT THE BEST",
	paragraphe: "They’re called bestsellers for a reason.",
	button: "SHOP NOW",
};

export const newReleasesShop = [
	{
		img: TBAR,
		title: "Legacy T-Bar Sports Bra",
		p: "Light Support",
		color: "white",
		price: "US$45",
		rate: 4.4,
	},
	{
		img: Leggins,
		title: "Legacy Leggings",
		p: "Body Fit",
		color: "Black",
		price: "US$55",
		rate: 4.7,
	},
	{
		img: Oversize,
		title: "Legacy Oversized Sweatshirt",
		p: "Oversized",
		color: "Black",
		price: "US$75",
		rate: 5,
	},
	{
		img: Tight,
		title: "Legacy Tight Shorts",
		p: "Body Fit",
		color: "Deep Olive Green",
		price: "US$50",
		rate: 5,
	},
];

export const seamlessShop = [
	{
		img: seamlessleggigns,
		title: "Sweat Seamless Leggings",
		p: "Body Fit",
		color: "Plum Pink",
		price: "US$75",
		rate: 4,
	},
	{
		img: seamlesscroptop,
		title: "Sweat Seamless Long Sleeve Crop Top",
		p: "Body Fit",
		color: "Regular Fit",
		price: "US$55",
		rate: 4.3,
	},
	{
		img: seamlessvital,
		title: "VITAL SEAMLESS 2.0 LEGGINGS",
		p: "Oversized",
		color: "Brown Marl",
		price: "US$75",
		rate: 5,
	},
	{
		img: seamlessvital2,
		title: "Legacy Tight Shorts",
		p: "Body Fit",
		color: "Deep Olive Green",
		price: "US$50",
		rate: 5,
	},
];
export const elevateShop = [
	{
		img: seamlessleggigns,
		title: "Sweat Seamless Leggings",
		p: "Body Fit",
		color: "Plum Pink",
		price: "US$75",
		rate: 4,
	},
	{
		img: seamlessvital2,
		title: "Legacy Tight Shorts",
		p: "Body Fit",
		color: "Deep Olive Green",
		price: "US$50",
		rate: 5,
	},

	{
		img: Leggins,
		title: "Legacy Leggings",
		p: "Body Fit",
		color: "Black",
		price: "US$55",
		rate: 4.7,
	},
	{
		img: Oversize,
		title: "Legacy Oversized Sweatshirt",
		p: "Oversized",
		color: "Black",
		price: "US$75",
		rate: 5,
	},
];
